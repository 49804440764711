/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui'
import { useMemo } from 'react'

import { useScroll } from '../hooks/useScroll'
import { useWindowSize } from '../hooks/useWindowSize'

export const Header = ({ siteTitle }) => {
  const { scrollY } = useScroll()
  const { width } = useWindowSize()
  const maxLetterSpace = useMemo(() => width / 1000, [width])

  return (
    <Flex
      sx={{
        py: [4, 5],
        px: 2,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          textTransform: 'lowercase',
          fontFamily: 'small',
          textDecoration: 'none',
          color: 'text',
          fontSize: 5,
          width: '100%',
          display: 'block',
          fontWeight: 300,
          textAlign: 'center',
          letterSpacing: `${Math.min(maxLetterSpace, scrollY * 0.01)}em`
        }}
      >
        {siteTitle}
      </Box>
    </Flex>
  )
}
