import React from 'react'
import { Grid } from 'theme-ui'

import { Header } from './Header'
import { Footer } from './Footer'

export const Layout = ({ children, siteTitle }) => (
  <>
    <Header siteTitle={siteTitle} />
    <Grid as="main">{children}</Grid>
    <Footer />
  </>
)
