/** @jsx jsx */
import { jsx, Box, Link } from 'theme-ui'

import { Dash } from './Dash'

export const Footer = () => (
  <Box
    as="footer"
    sx={{
      position: 'relative',
      overflow: 'hidden',
      height: ['75vmin', '50vmin'],
      maxHeight: '500px',
      minHeight: ['none', '360px']
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        right: '10%',
        bottom: '-18%',
        height: '100%',
        fontSize: [4, 4, 5],
        fontFamily: 'small',
        transform: 'scale(0.5) translate(50%, 50%) rotate(-25deg)'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 227 214"
        sx={{ height: ['75vmin', '50vmin'], minHeight: ['none', '360px'] }}
      >
        <path
          sx={{ fill: 'primary' }}
          d="M110.347-24.825L294.53 81.513c-28.823 50.729-74.354 86.566-136.59 107.514-62.237 20.947-115.545 14.518-159.924-19.288L110.347-24.825z"
          transform="rotate(60 146.273 88.111)"
        />
      </svg>
      <Link
        href="https://instagram.com/s0phiefleur"
        sx={{
          display: 'block',
          position: 'absolute',
          top: '0',
          left: '50%',
          textDecoration: 'none',
          transform: 'translate(-30%, calc(-150%))'
        }}
      >
        @s0phiefleur
      </Link>
      <Link
        href="https://instagram.com/ingovalente"
        sx={{
          display: 'block',
          position: 'absolute',
          top: '50%',
          right: 0,
          textDecoration: 'none',
          transform: 'rotate(90deg) translate(-46%, calc(-290%))'
        }}
      >
        @ingovalente
      </Link>
    </Box>
  </Box>
)
